.chevron {
    &[data-rotate] {
        transform: rotate(45deg);
    }

}

.icon {
    width: 14px;
    height: 20px;
    margin-right: 30px
}