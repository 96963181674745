@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800,900);




:root {
    --mantine-color-scheme: 'light' !important;
    --main-color: #4AD8FC;
    --base-gray: #000000;
    --off-gray: #f1f1f1;
    --item-gray: #9D9D9D;
    --text: #000000;
    --text-gray: #6B6A69;
    --trans: #ffffff00;
    --px11: 0.6875rem;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --px21: 1.3125rem;
    --px22: 1.375rem;
    --px23: 1.4375rem;
    --px24: 1.5rem;
    --px25: 1.5625rem;
    --px26: 1.625rem;
    --px27: 1.6875rem;
    --px28: 1.75rem;
    --px29: 1.8125rem;
    --px30: 1.875rem;
    --titleFont: 2.2rem;

    --background: #172B33;
    --hoverBorder: rgba(0, 0, 0, 0.068);
    --frostedBorder: solid 1px rgba(255, 255, 255, 0.113);
    --heroFont: 3.5rem;
    --frostedBackground: rgb(22 22 22);
    ;
    --lightSurface: #1d1d1da1;
    --glassBackground: rgb(255 255 255 / 2%);
    --glassSurface: rgb(255 255 255 / 4%);
    --glassText: rgba(255, 255, 255, 0.633);
    --grayBackground: #f1f3f5;
    --gradientedFrostedBorder: solid 1.5px;
    --frostedText: rgba(255, 255, 255, 0.7);
    --cornerRounding: 30px;
    --iconGray: rgba(106, 105, 106, 1);
    --headerHeight: 80px;
    --heroHeight: 700px;
    --edgePadding: 40px 100px;
    --edgePaddingHorizontal: 0px 100px;
}

button {
    cursor: pointer;
}

button:focus {
    outline: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    color: rgb(0, 0, 0);

}

body {
    background-color: var(--base-gray)
}



.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 40;
    top: 0;
    padding: var(--edgePadding);
}

.navbar.scrolled {
    background-color: var(--background);

}

.navbar-logo {
    width: 200px;
    object-fit: scale-down;
}

.navbar>ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 40px;
}

.navbar>ul>li>a {
    text-decoration: none;
    color: white;
    font-size: var(--px17);
    font-weight: 600;
}

.hero-banner {
    height: var(--heroHeight);
}

.hero-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    height: var(--heroHeight);

}

.hero-banner-text {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--edgePadding);
    color: white;
    width: 1000px;
}

.hero-banner-text>h1 {
    color: white;
    text-transform: uppercase;
    font-size: var(--heroFont);
    line-height: 1.2
}

.hero-banner-text>h1>span {
    color: var(--main-color);
}

.hero-banner-text>span {
    font-size: var(--px23);
    font-weight: 600;
    color: white
}

.hero-banner-text>p {
    font-size: var(--px18);
    color: white;
    margin-top: 0px;
    line-height: 35px;
    padding-right: 60px;
    margin-bottom: 25px;
}

.chevron-buttons {
    display: flex;
    gap: 20px;
}

.chevron-buttons>a {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: white;
    font-size: var(--px18);
    font-weight: 600;

}

.chevron-buttons>a>i {
    color: white;
    font-size: 1.8rem;
}

.chevron-buttons>a:first-of-type {
    background-color: var(--main-color);
    color: black;
    padding: 15px 25px;
    border-radius: 50px;
}

.chevron-buttons>a:first-of-type>i {
    color: black;
}

.hero-badge {

    width: 320px;

}


.hero-badge-container {
    position: absolute;
    right: 90px;
    width: 320px;
    margin-top: -80px;
}




















.landing-page-sections {
    margin: auto;
    max-width: 1800px;
}

.section-header {
    display: flex;
    justify-content: space-between;
    padding: var(--edgePaddingHorizontal);
    align-items: center;
}

.section-header>h2 {
    width: 480px;
    font-size: 30px;
    line-height: 1.4
}

.section-header>h2>span {
    color: var(--main-color);
}

.section-header>p {
    font-size: 18px;
    text-align: right;
    width: 400px;
}

.section-header.inverted-color>h2 {
    color: white;
}

.section-header.inverted-color>p {
    color: white;
}

.block-cards {
    display: flex;
    padding: var(--edgePaddingHorizontal);
    gap: 35px;
}

.block-card {
    width: 33.3%;
    border: solid 1px var(--off-gray);
    border-radius: 10px;
}

.block-card>img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.block-card>h3 {
    font-size: 35px;
    color: white;
    margin-top: -120px;
    width: 380px;
    line-height: 1.2;
    padding: 0px 30px
}

.block-card>h3>span {
    color: var(--main-color);
}

.block-card>p {
    padding: 0px 30px;
    font-size: 16px;
    ;
}

.block-card-text>span {
    font-size: 70px;
    font-weight: 700;
}

.block-card-text {
    display: flex;
    margin-top: 55px;
    padding: 0px 30px;
    justify-content: space-between;
    gap: 40px;
}

.block-card-container {
    display: flex;
    flex-direction: column;
    gap: 70px;
    padding: 80px 0px;
}











.specialty-section {
    background-color: var(--background);
    padding: 80px 0px;
    display: flex;
    flex-direction: column;
    gap: 60px;
}


.zigzag-container {
    padding: var(--edgePaddingHorizontal);
    margin-top: 80px;

}

.zigzag-item {
    display: grid;
    grid-template-columns: 1fr 1fr;


    height: 500px;
    width: 100%;
    border-top: solid 1px rgba(255, 255, 255, 0.102);

}



.zigzag-item:nth-child(odd) .img-zig {
    order: 0;
}

.zigzag-item:nth-child(odd) .zigzag-text {
    order: 2;
}


.zigzag-item:nth-child(even) .img-zig {
    order: 2;
}

.zigzag-item:nth-child(even) .zigzag-text {
    order: 1;
}


.zigzag-item:last-of-type {
    border-bottom: solid 1px rgba(255, 255, 255, 0.102);
}

.img-zig {
    display: flex;

    align-items: center;
    background-size: cover;

}

.zigzag-text {
    margin-left: 80px;
}

.zigzag-text>h3 {
    font-size: 45px;
    color: white;

    width: 100%;
    line-height: 1.2;

}

.zigzag-text>h3>span {
    color: var(--main-color);
}

.zigzag-text>p {
    font-size: 18px;
    color: white;
    margin-top: 5px;

    margin-bottom: 10px;
}

.zigzag-text>p>span {
    color: white;
    font-weight: 600;
}

.zigzag-text>span {
    font-size: 16px;
    font-weight: 600;
    color: white;
}

.img-zig>.zigzag-text>p {
    width: 300px;
}

.text-zag {
    display: flex;
    align-items: center;
}















.mantine-Accordion-item:where([data-active]) {
    background-color: rgb(251, 251, 251) !important;
}

.mantine-Accordion-root {
    margin: auto;
    width: 100%;


    border-radius: 12px;
    border: solid 1px rgba(0, 0, 0, 0.1);

}

.mantine-Accordion-control:hover {
    background-color: rgb(251, 251, 251) !important;
}


.mantine-Accordion-label {
    font-size: 18px;
    height: 100px;
    color: black !important;
    display: flex;
    align-items: center;
    font-weight: 700 !important;
}

.mantine-Accordion-control {
    padding: 0px 35px !important;
}

.mantine-Accordion-item {
    border-left: none !important;
    border-right: none !important;
}

.mantine-Accordion-item:first-of-type,
.mantine-Accordion-item:first-of-type>[data-accordion-control] {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-top: none !important;
}

.mantine-Accordion-item:last-of-type,
.mantine-Accordion-item:last-of-type>[data-accordion-control] {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-bottom: none !important;

}

.faq-accordion {
    padding: var(--edgePaddingHorizontal)
}

.faq-section>div>.section-header {
    margin: 70px 0px
}

.faq-section {
    margin-bottom: 100px;
}

















.footer {
    padding: var(--edgePaddingHorizontal);
    background-color: var(--background);
    padding-top: 120px
}

.footer-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px
}

.footer-links {
    display: flex;
    gap: 50px;
}

.footer-link-group>ul>li>a {
    text-decoration: none;
    color: white;
    font-size: var(--px15);
    font-weight: 500;
    line-height: 22px;
    margin-top: 2px;
}

.footer-logo>img {
    width: 180px;
    margin-bottom: 15px;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.footer-logo>a {
    text-decoration: none;
    color: white;
    ;
    font-weight: 600;
    font-size: var(--px14);
}

.footer-logo>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    color: white;
}



.footer-link-group>ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
}

.footer-bottom>ul {
    display: flex;
    list-style-type: none;
    gap: 20px;
}

.footer-bottom>ul>li>a {
    text-decoration: none;
    color: white;
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    margin-top: 2px;
}

.footer-link-group>ul>li>a:hover {
    opacity: 0.7;
    text-decoration: underline;
}

.footer-bottom {
    height: 65px;
    display: flex;
    align-items: center;
}

.footer-bottom>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    color: white;
}

.footer-link-group>h4 {
    font-size: var(--px15);
    font-weight: 700;
    color: white;
    margin-bottom: 5px;
}


.footer-link-group>ul>li>span {
    font-size: var(--px15);
    font-weight: 400;
    line-height: 22px;
    color: white;
    margin-top: 2px;
}

.footer-cta {
    width: 100%;
    background-color: var(--background);
    height: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--edgePaddingHorizontal);
}

.footer-cta>h1 {
    font-size: 45px;
    color: white;
    width: 800px;
    line-height: 1.3;
}

.footer-cta-text {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-cta-text>h3 {
    font-size: 26px;
    color: white;

    line-height: 1.3;
}

.footer-cta-text>p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.701);

    line-height: 1.8;
}








@media screen and (max-width: 1300px) {

    :root {
        --edgePadding: 30px 40px;
        --edgePaddingHorizontal: 0px 40px;
        --heroHeight: 100vh
    }

    .navbar>ul {
        display: none;
    }

    .navbar {
        justify-content: center;
    }

    .hero-banner-text {
        width: 100%;
    }

    .hero-badge-container {
        width: 100%;
        display: flex;
        justify-content: center;
        right: 0px;
    }

    .block-cards {
        flex-direction: column;
    }

    .block-card {
        width: 100%;
    }

    .section-header {
        flex-direction: column;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }

    .section-header>p {
        text-align: left;
        width: 100%;
    }

    .section-header>h2 {
        width: 100%;
    }

    /* Remove zigzag effect: ensure a single consistent order */
    .zigzag-item {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        height: 750px;
    }

    /* Remove alternating order rules */
    .zigzag-item:nth-child(odd) .img-zig,
    .zigzag-item:nth-child(odd) .zigzag-text,
    .zigzag-item:nth-child(even) .img-zig,
    .zigzag-item:nth-child(even) .zigzag-text {
        order: initial;
    }

    .img-zig {
        height: 100%;
    }

    .zigzag-text {
        margin-left: 0px;
    }

    .img-zig>.zigzag-text {
        margin-left: 50px;
    }
}


@media screen and (max-width: 600px) {
    :root {
        --edgePadding: 20px 20px;
        --edgePaddingHorizontal: 0px 20px;
        --heroHeight: 70vh;
    }


    .navbar {
        padding: var(--edgePadding);
    }

    .navbar-logo {
        width: 160px;
    }


    .hero-banner-text {
        padding: var(--edgePadding);
        width: 100%;
    }

    .hero-banner-text>h1 {
        font-size: 2rem;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 20px;
    }

    .hero-banner-text>span {
        font-size: var(--px18);
        text-align: center;
    }

    .hero-banner-text>p {
        font-size: var(--px16);
        line-height: 28px;
        padding-right: 0px;
        display: none;
        ;
        text-align: center;
    }


    .chevron-buttons {
        flex-direction: column;
        gap: 0px;
        align-items: center;
    }

    .chevron-buttons>a {
        font-size: 15px;
        padding: 10px 20px !important;
    }


    .section-header>h2 {
        font-size: var(--px20);
        width: 100%;
    }

    .section-header>p {
        font-size: var(--px16);
        width: 100%;
    }

    .block-card>h3 {
        font-size: var(--px24);
        width: 100%;
        margin-top: -90px;
        padding: var(--edgePaddingHorizontal)
    }

    .block-card>p {
        font-size: var(--px14);
        padding: var(--edgePaddingHorizontal)
    }

    .block-card-text {
        padding: var(--edgePaddingHorizontal);
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .block-card-text>span {
        font-size: 40px;
    }

    .block-cards {
        padding: var(--edgePaddingHorizontal);
        gap: 20px;
    }


    .zigzag-container {
        padding: 0px
    }

    .zigzag-item {
        height: 500px;
        grid-template-rows: auto auto;
        padding: 20px
    }



    .zigzag-text>h3 {
        font-size: 25px;
    }

    .zigzag-text>p {
        font-size: var(--px14);
        margin-bottom: 10px;
    }

    /* Footer: reduce spacing and font sizes */
    .footer {
        padding-top: 60px;
        padding-bottom: 30px;
    }

    .footer-content {
        flex-direction: column;
        gap: 40px;
        margin-bottom: 60px;
    }

    .footer-logo>img {
        width: 140px;
    }

    .footer-logo {
        width: 100%;
    }

    .footer-links {
        flex-direction: column;
        gap: 30px;
    }

    .footer-link-group>ul>li>a,
    .footer-link-group>ul>li>span {
        font-size: var(--px14);
    }

    .footer-bottom {
        flex-direction: column;
        gap: 15px;
        text-align: center;
    }

    .footer-bottom>p {
        font-size: var(--px14);
    }


    .footer-cta {
        flex-direction: column;
        height: 500px;
        align-items: flex-start;
        gap: 20px;
        padding: var(--edgePaddingHorizontal);
        justify-content: center;
    }

    .footer-cta>h1 {
        font-size: var(--px24);
        width: 100%;
        line-height: 1.2;
    }

    .footer-cta-text {
        width: 100%;
        gap: 10px;
    }

    .footer-cta-text>h3 {
        font-size: var(--px18);
    }

    .footer-cta-text>p {
        font-size: var(--px14);
        line-height: 1.4;
    }
}